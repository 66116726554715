import React, { Component } from 'react'
import { IContainerCustomization } from '../PartnerCustomizations'
import {
  UniKeyV1CredentialC,
  RawCredentialC,
  WiegandCredentialC,
  Corp1000CredentialC,
  TSBnFCredentialC,
  TSBwFCredentialC,
  ECredentialType,
  IPartnerCredImpls
} from '@unikey/unikey-commons/release/csupp'


const showSubBrand = true;

export default () => {

  const partnerCredImpls: IPartnerCredImpls = {
    PartnerRCred: RawCredentialC,
    PartnerWCred: WiegandCredentialC,
    PartnerU1Cred: UniKeyV1CredentialC,
    PartnerC1000Cred: Corp1000CredentialC,
    Partner37BwFCred: TSBwFCredentialC,
    Partner37BnFCred: TSBnFCredentialC,
  };


  const CredentialDetails: IContainerCustomization = {
    props: {
      partnerCredImpls,
    }
  }

  const CredentialList: IContainerCustomization = {
    props: {
      partnerCredImpls,
    }
  }

  const DealerList: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const DealerDetails: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const OrganizationList: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const OrganizationDetails: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const CouponAssignment: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const Portal: IContainerCustomization = {
    props: {
      hideCouponFlow: true
    }
  }
  
  const CouponList: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const CouponDetails: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }
  
  const AdminList: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }

  const AdminDetails: IContainerCustomization = {
    props: {
      showSubBrand
    }
  }


  return {
    Portal,
    CredentialDetails,
    CredentialList,
    DealerDetails,
    DealerList,
    OrganizationDetails,
    OrganizationList,
    CouponAssignment,
    CouponList,
    CouponDetails,
    AdminDetails,
    AdminList
  }
}
